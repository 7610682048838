<template>
  <div>
    <div class="title">{{ $t("baseInfo") }}</div>
    <el-form ref="form" :model="form" label-width="280px">
      <el-form-item :label="$t('brandOwnerNameColumn')">
        <span>{{ form.name }}</span>
      </el-form-item>
      <el-form-item :label="$t('businessAddress')">
        <span>{{ form.officeAddress }}</span>
      </el-form-item>
      <el-form-item :label="$t('theRegisteredCapital')">
        <span>
          {{ ((Number(form.registeredCapital || 0) / uniM).toFixed(2) || 0) | toThousandFilterTwo }}&nbsp;&nbsp;{{
          $t("millionDollars")
          }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('registeredAddress')">
        <span>{{ form.registeredAddress }}</span>
      </el-form-item>
      <el-form-item :label="$t('legalRepresentative')">
        <span>{{ form.directorName }}</span>
      </el-form-item>
      <el-form-item :label="$t('legalPersonId')">
        <span>{{ form.directorCertNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('legalPersonPhone')">
        <span>{{ form.directorMobileNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('businessMail')">
        <span>{{ form.corporateEmail }}</span>
      </el-form-item>
      <el-form-item :label="$t('socialCreditCode')" style="width: 100%">
        <span>{{ form.registeredNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('businessScope')" style="width: 100%">
        <span>{{ form.businessScope }}</span>
      </el-form-item>
      <el-form-item :label="$t('certificateIncorporation')" style="width: 100%">
        <el-link :underline="false" type="primary" @click="toReviewImg(form.coi)">
          {{
          form.coi &&
          form.coi.split("/")[form.coi.split("/").length - 1]
          }}
        </el-link>
      </el-form-item>
      <el-form-item :label="$t('businessRegistration')" style="width: 100%">
        <el-link :underline="false" type="primary" @click="toReviewImg(form.businessRegistration)">
          {{
          form.businessRegistration &&
          form.businessRegistration.split("/")[form.businessRegistration.split("/").length - 1]
          }}
        </el-link>
      </el-form-item>
      <el-form-item :label="$t('legalHoldingStructure')" style="width: 100%">
        <el-link :underline="false" type="primary" @click="toReviewImg(form.legalHoldingStrcture)">
          {{
          form.legalHoldingStrcture &&
          form.legalHoldingStrcture.split("/")[form.legalHoldingStrcture.split("/").length - 1]
          }}
        </el-link>
        <br />
      </el-form-item>
      <el-form-item :label="$t('directorsDocumentation')" style="width: 100%">
        <el-link :underline="false" type="primary" @click="toReviewImg(form.directorCertFront)">
          {{
          form.directorCertFront &&
          form.directorCertFront.split("/")[form.coi.split("/").length - 1]
          }}
        </el-link>
        <br />
        <el-link :underline="false" type="primary" @click="toReviewImg(form.directorCertBack)">
          {{
          form.directorCertBack &&
          form.directorCertBack.split("/")[form.coi.split("/").length - 1]
          }}
        </el-link>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("bankInformation") }}</div>
    <el-form ref="form" :model="form" style="width: 1100px" label-width="280px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" label-width="270px">
                <span v-if="form.areaId">{{ getAreaName(form.areaId) }}</span>
                <span v-else></span>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" label-width="280px">
            <div class="flex-row">
                <el-image v-if="form.receivingBankIcon" :src="form.receivingBankIcon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                <span v-if="language==='zh-cn'">{{ form.receivingBank }}</span>
                <span v-else>{{ form.receivingBankEn || form.receivingBank }}</span>
            </div>
        </el-form-item>
      <el-form-item :label="$t('swiftCode')" label-width="280px">
        <span>{{ form.swiftCode }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankNumber')">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('bankAccount')" label-width="280px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("purchaseTitle") }}</div>
    <el-form ref="form" :model="form" style="width: 1100px" label-width="280px">
      <el-form-item :label="$t('purchaseAmountYear')">
        <span>
          {{ ((Number(form.annualPurchaseAmount || 0) / uniM).toFixed(2) || 0) | toThousandFilterTwo }}&nbsp;&nbsp;{{
          $t("millionDollars")
          }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('numberOfFrameSuppliers')">
        <span>{{ form.suppliereNumber }}</span>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 30px">
      <el-button
        v-if="form.status && form.status.toString() === authAll.PROCESSING.valueNew"
        :disabled="form.status && form.status.toString() === authAll.UNKNOWN.valueNew || isSubmit"
        class="btn-black"
        v-on:click="approve(true)"
      >{{ $t("approve") }}</el-button>
      <el-button
        v-if="form.status && form.status.toString() === authAll.PROCESSING.valueNew"
        :disabled="form.status && form.status.toString() === authAll.UNKNOWN.valueNew || isSubmit"
        class="btn-gray"
        v-on:click="approve(false)"
      >{{ $t("reject") }}</el-button>
      <el-button
        v-if="!form.status || form.status.toString() !== authAll.PROCESSING.valueNew"
        class="btn-black"
        v-on:click="close"
      >{{ $t("btnI18n.Close") }}</el-button>
    </div>
  </div>
</template>

<script>
import {getObjByKeyValue} from "@/utils";
export default {
  name: "brand-owners-detail",
  data() {
    return {
      uniM: this.$enums.UNIT_MILLION,
      auth: this.$route.query.auth,
      authAll: this.$enums.AUTH,
      isSubmit: false,
      form: {},
      address: undefined,
        // bank update
        optionsCountry: [],
        // bank update
    };
  },
  created() {
      this.getAreaList();
    this.initData();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getAreaName(value) {
          if (!value) return "";
          if (!this.optionsCountry.length) return "";
          if (this.language === "zh-cn") {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).cnName;
          } else {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).enName;
          }
      },
      // bank update
    toReviewImg(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    initData() {
      let _this = this;
      this.$axios.get("/manage-brand-owner/detail", { params: { userId: this.$route.query.id } }).then(result => {
        if (!result.code && result.data) {
          _this.form = result.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    async approve(status) {
      this.isSubmit = true;
      let _this = this;
      let approve = status ? this.authAll.CONFIRM.valueNew : this.authAll.REJECT.valueNew;
      this.$axios.post("/manage-brand-owner/review", { status: approve, userId: this.$route.query.id }).then(result => {
        if (!result.code) {
          _this.isSubmit = false;
          _this.close();
        }
      }).catch(error => {
        this.isSubmit = false;
        console.log(error);
      });
    },
    close() {
      this.$router.replace({ path: "/brand-owners-manage" });
    }
  },
    computed: {
        language() {
            return this.$store.getters['auth/language'];
        }
    },
    watch: {
        language() {
            console.log(this.language);
        }
    }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
  margin-bottom: 0;
}
</style>
